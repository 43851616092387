import React from "react"

import Layout from "../components/layout"
import {Link} from 'gatsby' 
import Arrow from '../images/arrow.svg'
import SEO from "../components/seo"

const NotFoundPage = () => (
    <Layout>
        <SEO title="Polityka Prywatności" />
        <dl style={{ margin:"auto", padding:'0 1em', maxWidth:'1024px'}}>
            <dt style={{fontSize:"2em", fontFamily:'"Bebas neue"', marginBottom:'1em', textAlign:'center' }}>Polityka Prywatności</dt>
            <dd>
                <p>Ta strona korzysta z plików cookie.Cookie to niewielki plik zainstalowany na Twoim komputerze, telefonie komórkowym lub tablecie, podczas gdy wchodzisz na stronę internetową.Jedyne pliki cookie, których używamy, to pliki cookie służące do pomiaru ruchu internetowego.Pozwalają nam dostosować stronę internetową do życzeń odwiedzających, mierząc liczbę odwiedzin, liczbę odwiedzanych stron oraz aktywność odwiedzających na stronie internetowej.</p>

                <p>Strona internetowa w celu poprawnego działania używa plików cookies.Korzystając ze strony wyrażasz zgodę na używanie cookies, zgodnie z aktualnymi ustawieniami przeglądarki.Strona internetowa nie zbiera w sposób automatyczny żadnych informacji, poza informacjami zawartymi w plikach cookies.</p>

                <p>Podmiotem zamieszczającym na urządzeniu końcowym użytkownika strony internetowej pliki cookies oraz uzyskującym do nich dostęp jest wydawca strony internetowej, Komfortum sp.z o.o., a także podmioty pracujące na zlecenie wydawcy i mające dostęp do zawartości strony.</p>

                <p>Pliki cookies wykorzystywane są w celu: </p>
                <ul style={{fontWeight:'300', fontSize:'1.4em', lineHeight:'1.5em'}}>
                    <li>
                    dostosowania treści strony internetowej do preferencji użytkownika oraz optymalizacji korzystania ze strony internetowej.Pliki cookies pozwalają rozpoznać urządzenie użytkownika strony internetowej i odpowiednio wyświetlić stronę internetową, dostosowaną do indywidualnych potrzeb użytkownika;</li>
                    <li>tworzenia statystyk i analiz, które umożliwiają zrozumienie sposobu korzystania ze strony internetowej, co pozwala ulepszać zawartość i dostęp do treści;</li>
                </ul>
            </dd>
        </dl>
        <Link style={{display:'block', margin:'auto', width:'fit-content'}} to="/"><button className="kmf-button">Powrót<img src={Arrow} alt="strzałka" /></button></Link>
    </Layout>
)

export default NotFoundPage
